var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.featuresBlock]},[_c(_vm.bannerData.headerTag || 'h2',{tag:"component",class:['h2',_vm.$style.bannerHeader]},[_vm._v("\n    "+_vm._s(_vm.bannerData.header)+"\n  ")]),_vm._v(" "),_c('div',{class:['row', _vm.$style.featuresRow,
             ( _obj = {}, _obj[_vm.$style.featuresBlock__SMALL] = _vm.mediaCount, _obj )
    ]},[_vm._l((_vm.bannerGallery),function(feature,index){
    var _obj;
return [(feature.properties)?_c(feature.properties.link ? 'UiLink' : 'div',{key:index,tag:"component",class:[
          'col',
          ( _obj = {}, _obj['col-sm-4 col-md-3 col-lg-2'] = _vm.mediaCount, _obj ),
          _vm.$style.featuresItem
        ],attrs:{"to":feature.properties.link}},[_c('div',{class:_vm.$style.featuresImg},[_c('UiImage',{class:_vm.$style.image,attrs:{"image":{
              publicUrl: feature.publicUrl,
              alt: feature.properties.alternative || feature.properties.title
            }}})],1),_vm._v(" "),(feature.properties.title)?_c('p',{class:['h4', _vm.$style.title]},[_vm._v("\n          "+_vm._s(feature.properties.title)+"\n        ")]):_vm._e(),_vm._v(" "),(feature.properties.description)?_c('p',{class:_vm.$style.description},[_vm._v("\n          "+_vm._s(feature.properties.description)+"\n        ")]):_vm._e()]):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }