






















































import Vue, { PropType } from 'vue'
import UiRichText from '../../UiRichText/UiRichText.vue'
import UiImage from '../../UiImage'
import { BannerData, Image } from '../UiTextWithImages.types'

export default Vue.extend({
  name: 'UiFeaturesBlock',
  components: {
    UiRichText,
    UiImage
  },
  props: {
    bannerData: {
      type: Object as PropType<BannerData>,
      required: true
    },
    bannerGallery: {
      type: Array as PropType<Image[]>,
      required: true
    }
  },
  computed: {
    mediaCount (): boolean {
      return this.bannerGallery.length > 4
    }
  }
})

