// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1OvD{text-align:center;padding-top:2.4rem;padding-bottom:2.4rem}._1OvD a:hover{text-decoration:underline}._3jFQ{height:87px;display:flex;align-items:center;justify-content:center}._2wdd{max-height:87px;width:auto}._1QeI{display:flex;justify-content:center;padding-top:5.2rem}._3N2W{margin:.8rem 0 0;word-break:normal}._24VY{margin:1.6rem 0 0}._3FzV{padding-bottom:4rem}@media (max-width:35.9375em){._3FzV{flex-basis:auto}}._28BU{justify-content:left}._28BU ._3N2W{font-size:2rem}._28BU ._24VY{font-size:1.4rem;line-height:1.6rem}._28BU ._3jFQ{height:80px}._28BU ._2wdd{max-height:80px}html[dir=rtl] ._28BU{justify-content:right}", ""]);
// Exports
exports.locals = {
	"featuresBlock": "_1OvD",
	"featuresImg": "_3jFQ",
	"image": "_2wdd",
	"featuresRow": "_1QeI",
	"title": "_3N2W",
	"description": "_24VY",
	"featuresItem": "_3FzV",
	"featuresBlock__SMALL": "_28BU"
};
module.exports = exports;
